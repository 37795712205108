// import { useClient } from '../../client/client';
// import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

export function useDDDOptions() {
  // const client = useClient('RegionItemFindMany', {
  //   fetchOnMount: true,
  //   cache: true,
  //   variables: {
  //     filter: {
  //       level: 2,
  //     },
  //     limit: 1000,
  //   },
  // });
  //
  // return useMemo(() => {
  //   return sortBy(
  //     verdade(client.result).map(({ name, _id }) => ({
  //       label: name || '',
  //       value: _id,
  //     })),
  //     'label'
  //   );
  // }, [client.loading]);

  return sortBy(
    apiData.map(({ name, _id, UF }) => ({
      label: name || '',
      value: _id,
      UF
    })),
    'label'
  );
}

const apiData = [
  {
    _id: '_BR_RO_69_',
    name: '69',
    abbreviation: '69',
    level: 2,
    UF: 'RO',
    DDD: '69',
    parentId: '_BR_RO_',
    IBGE: 110020,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.657Z',
    createdAt: '2020-02-07T00:42:52.657Z'
  },
  {
    _id: '_BR_AM_97_',
    name: '97',
    abbreviation: '97',
    level: 2,
    UF: 'AM',
    DDD: '97',
    parentId: '_BR_AM_',
    IBGE: 130426,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.657Z',
    createdAt: '2020-02-07T00:42:52.657Z'
  },
  {
    _id: '_BR_AM_92_',
    name: '92',
    abbreviation: '92',
    level: 2,
    UF: 'AM',
    DDD: '92',
    parentId: '_BR_AM_',
    IBGE: 130260,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.657Z',
    createdAt: '2020-02-07T00:42:52.657Z'
  },
  {
    _id: '_BR_PA_94_',
    name: '94',
    abbreviation: '94',
    level: 2,
    UF: 'PA',
    DDD: '94',
    parentId: '_BR_PA_',
    IBGE: 150810,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.658Z',
    createdAt: '2020-02-07T00:42:52.658Z'
  },
  {
    _id: '_BR_PA_91_',
    name: '91',
    abbreviation: '91',
    level: 2,
    UF: 'PA',
    DDD: '91',
    parentId: '_BR_PA_',
    IBGE: 150760,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.658Z',
    createdAt: '2020-02-07T00:42:52.658Z'
  },
  {
    _id: '_BR_PA_93_',
    name: '93',
    abbreviation: '93',
    level: 2,
    UF: 'PA',
    DDD: '93',
    parentId: '_BR_PA_',
    IBGE: 150060,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.658Z',
    createdAt: '2020-02-07T00:42:52.658Z'
  },
  {
    _id: '_BR_AP_96_',
    name: '96',
    abbreviation: '96',
    level: 2,
    UF: 'AP',
    DDD: '96',
    parentId: '_BR_AP_',
    IBGE: 160060,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.658Z',
    createdAt: '2020-02-07T00:42:52.658Z'
  },
  {
    _id: '_BR_MA_98_',
    name: '98',
    abbreviation: '98',
    level: 2,
    UF: 'MA',
    DDD: '98',
    parentId: '_BR_MA_',
    IBGE: 211400,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.659Z',
    createdAt: '2020-02-07T00:42:52.659Z'
  },
  {
    _id: '_BR_MA_99_',
    name: '99',
    abbreviation: '99',
    level: 2,
    UF: 'MA',
    DDD: '99',
    parentId: '_BR_MA_',
    IBGE: 211220,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.658Z',
    createdAt: '2020-02-07T00:42:52.658Z'
  },
  {
    _id: '_BR_PI_89_',
    name: '89',
    abbreviation: '89',
    level: 2,
    UF: 'PI',
    DDD: '89',
    parentId: '_BR_PI_',
    IBGE: 220890,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.659Z',
    createdAt: '2020-02-07T00:42:52.659Z'
  },
  {
    _id: '_BR_PI_86_',
    name: '86',
    abbreviation: '86',
    level: 2,
    UF: 'PI',
    DDD: '86',
    parentId: '_BR_PI_',
    IBGE: 221100,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.659Z',
    createdAt: '2020-02-07T00:42:52.659Z'
  },
  {
    _id: '_BR_TO_63_',
    name: '63',
    abbreviation: '63',
    level: 2,
    UF: 'TO',
    DDD: '63',
    parentId: '_BR_TO_',
    IBGE: 172100,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.658Z',
    createdAt: '2020-02-07T00:42:52.658Z'
  },
  {
    _id: '_BR_RN_84_',
    name: '84',
    abbreviation: '84',
    level: 2,
    UF: 'RN',
    DDD: '84',
    parentId: '_BR_RN_',
    IBGE: 241200,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.660Z',
    createdAt: '2020-02-07T00:42:52.660Z'
  },
  {
    _id: '_BR_PB_83_',
    name: '83',
    abbreviation: '83',
    level: 2,
    UF: 'PB',
    DDD: '83',
    parentId: '_BR_PB_',
    IBGE: 251390,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.661Z',
    createdAt: '2020-02-07T00:42:52.661Z'
  },
  {
    _id: '_BR_CE_88_',
    name: '88',
    abbreviation: '88',
    level: 2,
    UF: 'CE',
    DDD: '88',
    parentId: '_BR_CE_',
    IBGE: 231050,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.660Z',
    createdAt: '2020-02-07T00:42:52.660Z'
  },
  {
    _id: '_BR_SE_79_',
    name: '79',
    abbreviation: '79',
    level: 2,
    UF: 'SE',
    DDD: '79',
    parentId: '_BR_SE_',
    IBGE: 280480,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.662Z',
    createdAt: '2020-02-07T00:42:52.662Z'
  },
  {
    _id: '_BR_PE_87_',
    name: '87',
    abbreviation: '87',
    level: 2,
    UF: 'PE',
    DDD: '87',
    parentId: '_BR_PE_',
    IBGE: 261410,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.661Z',
    createdAt: '2020-02-07T00:42:52.661Z'
  },
  {
    _id: '_BR_AL_82_',
    name: '82',
    abbreviation: '82',
    level: 2,
    UF: 'AL',
    DDD: '82',
    parentId: '_BR_AL_',
    IBGE: 270920,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.662Z',
    createdAt: '2020-02-07T00:42:52.662Z'
  },
  {
    _id: '_BR_BA_77_',
    name: '77',
    abbreviation: '77',
    level: 2,
    UF: 'BA',
    DDD: '77',
    parentId: '_BR_BA_',
    IBGE: 293330,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.663Z',
    createdAt: '2020-02-07T00:42:52.663Z'
  },
  {
    _id: '_BR_BA_75_',
    name: '75',
    abbreviation: '75',
    level: 2,
    UF: 'BA',
    DDD: '75',
    parentId: '_BR_BA_',
    IBGE: 290110,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.663Z',
    createdAt: '2020-02-07T00:42:52.663Z'
  },
  {
    _id: '_BR_BA_73_',
    name: '73',
    abbreviation: '73',
    level: 2,
    UF: 'BA',
    DDD: '73',
    parentId: '_BR_BA_',
    IBGE: 293135,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.663Z',
    createdAt: '2020-02-07T00:42:52.663Z'
  },
  {
    _id: '_BR_BA_74_',
    name: '74',
    abbreviation: '74',
    level: 2,
    UF: 'BA',
    DDD: '74',
    parentId: '_BR_BA_',
    IBGE: 291840,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.663Z',
    createdAt: '2020-02-07T00:42:52.663Z'
  },
  {
    _id: '_BR_BA_71_',
    name: '71',
    abbreviation: '71',
    level: 2,
    UF: 'BA',
    DDD: '71',
    parentId: '_BR_BA_',
    IBGE: 293070,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.665Z',
    createdAt: '2020-02-07T00:42:52.665Z'
  },
  {
    _id: '_BR_MG_38_',
    name: '38',
    abbreviation: '38',
    level: 2,
    UF: 'MG',
    DDD: '38',
    parentId: '_BR_MG_',
    IBGE: 314330,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.666Z',
    createdAt: '2020-02-07T00:42:52.666Z'
  },
  {
    _id: '_BR_MG_31_',
    name: '31',
    abbreviation: '31',
    level: 2,
    UF: 'MG',
    DDD: '31',
    parentId: '_BR_MG_',
    IBGE: 313665,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.666Z',
    createdAt: '2020-02-07T00:42:52.666Z'
  },
  {
    _id: '_BR_ES_27_',
    name: '27',
    abbreviation: '27',
    level: 2,
    UF: 'ES',
    DDD: '27',
    parentId: '_BR_ES_',
    IBGE: 320530,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.668Z',
    createdAt: '2020-02-07T00:42:52.668Z'
  },
  {
    _id: '_BR_MG_34_',
    name: '34',
    abbreviation: '34',
    level: 2,
    UF: 'MG',
    DDD: '34',
    parentId: '_BR_MG_',
    IBGE: 314800,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.666Z',
    createdAt: '2020-02-07T00:42:52.666Z'
  },
  {
    _id: '_BR_MG_33_',
    name: '33',
    abbreviation: '33',
    level: 2,
    UF: 'MG',
    DDD: '33',
    parentId: '_BR_MG_',
    IBGE: 316860,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.666Z',
    createdAt: '2020-02-07T00:42:52.666Z'
  },
  {
    _id: '_BR_PR_43_',
    name: '43',
    abbreviation: '43',
    level: 2,
    UF: 'PR',
    DDD: '43',
    parentId: '_BR_PR_',
    IBGE: 411310,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_SC_49_',
    name: '49',
    abbreviation: '49',
    level: 2,
    UF: 'SC',
    DDD: '49',
    parentId: '_BR_SC_',
    IBGE: 421005,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_SC_47_',
    name: '47',
    abbreviation: '47',
    level: 2,
    UF: 'SC',
    DDD: '47',
    parentId: '_BR_SC_',
    IBGE: 421085,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_PR_42_',
    name: '42',
    abbreviation: '42',
    level: 2,
    UF: 'PR',
    DDD: '42',
    parentId: '_BR_PR_',
    IBGE: 411990,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_ES_28_',
    name: '28',
    abbreviation: '28',
    level: 2,
    UF: 'ES',
    DDD: '28',
    parentId: '_BR_ES_',
    IBGE: 320332,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.668Z',
    createdAt: '2020-02-07T00:42:52.668Z'
  },
  {
    _id: '_BR_RS_53_',
    name: '53',
    abbreviation: '53',
    level: 2,
    UF: 'RS',
    DDD: '53',
    parentId: '_BR_RS_',
    IBGE: 430160,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.671Z',
    createdAt: '2020-02-07T00:42:52.671Z'
  },
  {
    _id: '_BR_RS_54_',
    name: '54',
    abbreviation: '54',
    level: 2,
    UF: 'RS',
    DDD: '54',
    parentId: '_BR_RS_',
    IBGE: 431410,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.671Z',
    createdAt: '2020-02-07T00:42:52.671Z'
  },
  {
    _id: '_BR_MS_67_',
    name: '67',
    abbreviation: '67',
    level: 2,
    UF: 'MS',
    DDD: '67',
    parentId: '_BR_MS_',
    IBGE: 500320,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.671Z',
    createdAt: '2020-02-07T00:42:52.671Z'
  },
  {
    _id: '_BR_PR_44_',
    name: '44',
    abbreviation: '44',
    level: 2,
    UF: 'PR',
    DDD: '44',
    parentId: '_BR_PR_',
    IBGE: 410752,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_MT_66_',
    name: '66',
    abbreviation: '66',
    level: 2,
    UF: 'MT',
    DDD: '66',
    parentId: '_BR_MT_',
    IBGE: 510760,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.672Z',
    createdAt: '2020-02-07T00:42:52.672Z'
  },
  {
    _id: '_BR_PR_46_',
    name: '46',
    abbreviation: '46',
    level: 2,
    UF: 'PR',
    DDD: '46',
    parentId: '_BR_PR_',
    IBGE: 412860,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_MT_65_',
    name: '65',
    abbreviation: '65',
    level: 2,
    UF: 'MT',
    DDD: '65',
    parentId: '_BR_MT_',
    IBGE: 510840,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.672Z',
    createdAt: '2020-02-07T00:42:52.672Z'
  },
  {
    _id: '_BR_RS_55_',
    name: '55',
    abbreviation: '55',
    level: 2,
    UF: 'RS',
    DDD: '55',
    parentId: '_BR_RS_',
    IBGE: 432220,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.670Z',
    createdAt: '2020-02-07T00:42:52.670Z'
  },
  {
    _id: '_BR_GO_64_',
    name: '64',
    abbreviation: '64',
    level: 2,
    UF: 'GO',
    DDD: '64',
    parentId: '_BR_GO_',
    IBGE: 520350,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.672Z',
    createdAt: '2020-02-07T00:42:52.672Z'
  },
  {
    _id: '_BR_CE_85_',
    name: '85',
    abbreviation: '85',
    level: 2,
    UF: 'CE',
    DDD: '85',
    parentId: '_BR_CE_',
    IBGE: 230770,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.672Z',
    createdAt: '2020-02-07T00:42:52.672Z'
  },
  {
    _id: '_BR_GO_62_',
    name: '62',
    abbreviation: '62',
    level: 2,
    UF: 'GO',
    DDD: '62',
    parentId: '_BR_GO_',
    IBGE: 522200,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.672Z',
    createdAt: '2020-02-07T00:42:52.672Z'
  },
  {
    _id: '_BR_PE_81_',
    name: '81',
    abbreviation: '81',
    level: 2,
    UF: 'PE',
    DDD: '81',
    parentId: '_BR_PE_',
    IBGE: 261640,
    region: 'TNE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.673Z',
    createdAt: '2020-02-07T00:42:52.673Z'
  },
  {
    _id: '_BR_MG_37_',
    name: '37',
    abbreviation: '37',
    level: 2,
    UF: 'MG',
    DDD: '37',
    parentId: '_BR_MG_',
    IBGE: 311150,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.675Z',
    createdAt: '2020-02-07T00:42:52.675Z'
  },
  {
    _id: '_BR_MG_32_',
    name: '32',
    abbreviation: '32',
    level: 2,
    UF: 'MG',
    DDD: '32',
    parentId: '_BR_MG_',
    IBGE: 313670,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.674Z',
    createdAt: '2020-02-07T00:42:52.674Z'
  },
  {
    _id: '_BR_MG_35_',
    name: '35',
    abbreviation: '35',
    level: 2,
    UF: 'MG',
    DDD: '35',
    parentId: '_BR_MG_',
    IBGE: 311360,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.674Z',
    createdAt: '2020-02-07T00:42:52.674Z'
  },
  {
    _id: '_BR_PR_41_',
    name: '41',
    abbreviation: '41',
    level: 2,
    UF: 'PR',
    DDD: '41',
    parentId: '_BR_PR_',
    IBGE: 412550,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.694Z',
    createdAt: '2020-02-07T00:42:52.694Z'
  },
  {
    _id: '_BR_PR_45_',
    name: '45',
    abbreviation: '45',
    level: 2,
    UF: 'PR',
    DDD: '45',
    parentId: '_BR_PR_',
    IBGE: 412085,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.694Z',
    createdAt: '2020-02-07T00:42:52.694Z'
  },
  {
    _id: '_BR_SC_48_',
    name: '48',
    abbreviation: '48',
    level: 2,
    UF: 'SC',
    DDD: '48',
    parentId: '_BR_SC_',
    IBGE: 421430,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.707Z',
    createdAt: '2020-02-07T00:42:52.707Z'
  },
  {
    _id: '_BR_RJ_24_',
    name: '24',
    abbreviation: '24',
    level: 2,
    UF: 'RJ',
    DDD: '24',
    parentId: '_BR_RJ_',
    IBGE: 330630,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.721Z',
    createdAt: '2020-02-07T00:42:52.721Z'
  },
  {
    _id: '_BR_AC_68_',
    name: '68',
    abbreviation: '68',
    level: 2,
    UF: 'AC',
    DDD: '68',
    parentId: '_BR_AC_',
    IBGE: 120040,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.710Z',
    createdAt: '2020-02-07T00:42:52.710Z'
  },
  {
    _id: '_BR_RR_95_',
    name: '95',
    abbreviation: '95',
    level: 2,
    UF: 'RR',
    DDD: '95',
    parentId: '_BR_RR_',
    IBGE: 140010,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.717Z',
    createdAt: '2020-02-07T00:42:52.717Z'
  },
  {
    _id: '_BR_RJ_22_',
    name: '22',
    abbreviation: '22',
    level: 2,
    UF: 'RJ',
    DDD: '22',
    parentId: '_BR_RJ_',
    IBGE: 330452,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.733Z',
    createdAt: '2020-02-07T00:42:52.733Z'
  },
  {
    _id: '_BR_SP_18_',
    name: '18',
    abbreviation: '18',
    level: 2,
    UF: 'SP',
    DDD: '18',
    parentId: '_BR_SP_',
    IBGE: 354140,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_SP_19_',
    name: '19',
    abbreviation: '19',
    level: 2,
    UF: 'SP',
    DDD: '19',
    parentId: '_BR_SP_',
    IBGE: 355620,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_RJ_21_',
    name: '21',
    abbreviation: '21',
    level: 2,
    UF: 'RJ',
    DDD: '21',
    parentId: '_BR_RJ_',
    IBGE: 330580,
    region: 'TSE',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.734Z',
    createdAt: '2020-02-07T00:42:52.734Z'
  },
  {
    _id: '_BR_SP_11_',
    name: '11',
    abbreviation: '11',
    level: 2,
    UF: 'SP',
    DDD: '11',
    parentId: '_BR_SP_',
    IBGE: 355650,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_SP_17_',
    name: '17',
    abbreviation: '17',
    level: 2,
    UF: 'SP',
    DDD: '17',
    parentId: '_BR_SP_',
    IBGE: 354980,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_SP_16_',
    name: '16',
    abbreviation: '16',
    level: 2,
    UF: 'SP',
    DDD: '16',
    parentId: '_BR_SP_',
    IBGE: 355170,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_SP_15_',
    name: '15',
    abbreviation: '15',
    level: 2,
    UF: 'SP',
    DDD: '15',
    parentId: '_BR_SP_',
    IBGE: 355700,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_SP_12_',
    name: '12',
    abbreviation: '12',
    level: 2,
    UF: 'SP',
    DDD: '12',
    parentId: '_BR_SP_',
    IBGE: 355410,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.737Z',
    createdAt: '2020-02-07T00:42:52.737Z'
  },
  {
    _id: '_BR_SP_13_',
    name: '13',
    abbreviation: '13',
    level: 2,
    UF: 'SP',
    DDD: '13',
    parentId: '_BR_SP_',
    IBGE: 355100,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.737Z',
    createdAt: '2020-02-07T00:42:52.737Z'
  },
  {
    _id: '_BR_SP_14_',
    name: '14',
    abbreviation: '14',
    level: 2,
    UF: 'SP',
    DDD: '14',
    parentId: '_BR_SP_',
    IBGE: 353470,
    region: 'TSP',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.736Z',
    createdAt: '2020-02-07T00:42:52.736Z'
  },
  {
    _id: '_BR_SC_42_',
    name: '42',
    abbreviation: '42',
    level: 2,
    UF: 'SC',
    DDD: '42',
    parentId: '_BR_SC_',
    IBGE: 421360,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.770Z',
    createdAt: '2020-02-07T00:42:52.770Z'
  },
  {
    _id: '_BR_RS_51_',
    name: '51',
    abbreviation: '51',
    level: 2,
    UF: 'RS',
    DDD: '51',
    parentId: '_BR_RS_',
    IBGE: 432300,
    region: 'TSL',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.772Z',
    createdAt: '2020-02-07T00:42:52.772Z'
  },
  {
    _id: '_BR_GO_61_',
    name: '61',
    abbreviation: '61',
    level: 2,
    UF: 'GO',
    DDD: '61',
    parentId: '_BR_GO_',
    IBGE: 520800,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.875Z',
    createdAt: '2020-02-07T00:42:52.875Z'
  },
  {
    _id: '_BR_DF_61_',
    name: '61',
    abbreviation: '61',
    level: 2,
    UF: 'DF',
    DDD: '61',
    parentId: '_BR_DF_',
    IBGE: 530010,
    region: 'TCN',
    capital: null,
    isVirtualRegion: null,
    updatedAt: '2020-02-07T00:42:52.896Z',
    createdAt: '2020-02-07T00:42:52.896Z'
  }
];
