import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Button, Select } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';

import Layout from '../components/Layout';
import { TopNav } from '../components/TopNav/TopNav';
import { useDDDOptions } from '../utils/useDDDOptions';
import { useServiceOptions } from '../utils/useServiceOptions';
import { useLess } from '../utils/useLess';

const Option = Select.Option;

const IndexWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const SelectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 240px;
  max-height: max-content;
  align-items: center;
  justify-content: center;
  .ant-select {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Index = (props: TIndexProps) => {
  const { className } = props;

  const router = useRouter();

  const initialDDD = router.query?.ddd as string | undefined;
  const initialService = router.query?.service as string | undefined;

  const state = useLess(
    () => ({
      ddd: initialDDD,
      service: initialService
    }),
    []
  );

  const ddds = useDDDOptions();
  const services = useServiceOptions();

  return (
    <Layout>
      <IndexWrapper className={cx(className, 'ui-flex Index Index_Wrapper')}>
        <TopNav />

        <SelectsWrapper>
          <Select
            showSearch
            virtual={false}
            value={state.ddd as any}
            placeholder={'Selecione o DDD'}
            onChange={(value: string) => {
              state.ddd = value;
            }}
          >
            {ddds.map((ddd) => (
              <Option value={ddd.value} key={ddd.value}>
                {`${ddd.label} (${ddd.UF})`}
              </Option>
            ))}
          </Select>

          <Select
            showSearch
            placeholder={'Selecione o Serviço'}
            onChange={(value: string) => {
              state.service = value;
            }}
          >
            {services.map((service) => (
              <Option value={service.value} key={service.value}>
                {service.label}
              </Option>
            ))}
          </Select>

          <Button
            type={'primary'}
            className={'ui-full ui-upper'}
            disabled={!state.service || !state.ddd}
            onClick={(ev) => {
              ev.preventDefault();
              router.push({
                pathname: '/folhetos',
                query: state
              });
            }}
          >
            Ver folhetos disponíveis
          </Button>
        </SelectsWrapper>
      </IndexWrapper>
    </Layout>
  );
};

export default Index;

export type TIndexProps = {
  className?: string;
  children?: ReactNode;
};
